import React from 'react'
import aws from '../assets/icons/aws.svg'


const ProjectTile = ({ logo, title, technologies }) => (
    <div className="relative p-8">
        <div className="flex flex-col items-center justify-center p-4 bg-white rounded">
            <div className="w-20 h-20 mb-6 mt-4">
                <img src={logo} alt={title} /> 
            </div>

            <span className='text-base font-medium'>{title}</span>
        </div>
    </div>
);


function Projects() {
    return (
        <ProjectTile logo={aws} title="AWS" technologies={"Glue" }></ProjectTile>
    )
}

export default Projects
