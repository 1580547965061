import React from 'react';
import WorkExp from './WorkExp';
import SkillsGrid from './SkillsGrid';
import Projects from './projects';
import Loader from './Loader';

function Content() {
  return (
    <div>
      <div id="Work Experience">
        <WorkExp />
        </div>
        <div id="Skills">
        <SkillsGrid/>
        </div>
        <div id="Projects">
          <Loader/>
        </div>
    </div>
  );
}


export default Content
