import React from 'react';
import locationlogo from '../assets/icons/location.svg';
import companylogo from '../assets/icons/company.svg';

function WorkExpTile({period,role,company,location,responsibilities}) {
      return (
        <div className="bg-white p-8 mt-4 rounded">
          <h2 className="text-2xl font-bold">{period}</h2>
          <h3 className="text-xl font-semibold text-blue-500">{role}</h3>
          <div className='flex'>
          <img className='w-6 h-6' src={companylogo} alt=''/> 
          <p className="text-lg font-semibold text-gray-500 ml-2">{company}</p>
          </div>
          <div className='flex'>
          <img className='w-6 h-6' src={locationlogo} alt=''/> 
          <p className="text-lg font-semibold text-gray-500 ml-2">{location}</p>
          </div>
          
          <ul className="list-disc pl-6 mt-4 space-y-2">
            {responsibilities && responsibilities.map((responsibility, index) => (
              <li key={index} className="text-base font-medium">
                {responsibility}
              </li>
            ))}
          </ul>
        </div>
      );
}


function WorkExp() {
    const workexperiences = [{
        period: 'July 2022 - Present',
        role: 'Software Engineer',
        company: 'Consultadd Inc.',
        location: 'Pune, Maharashtra, INDIA',
        responsibilities: [
          'Excelled in collaborative, agile software development, providing mentorship and consistently earning recognition, including 5 ”Employee of the Month” awards. Demonstrated problem-solving skills, adaptability, and effective communication for smooth code review processes and timely project delivery.',
          'Broadened technical expertise by learning and integrating new technologies and frameworks. Developed scalable REST APIs using Python frameworks (Django, Flask) for diverse applications like a trading platform and data analytics. Enhanced security with JWT authentication protocols.',
          'Engineered and seamlessly integrated a sophisticated billing system, enabling precise tracking of Azure cloud- based microservice usage for clients. Developed robust account creation workflows using Celery and RabbitMQ. These enhancements streamlined processes and reduced account opening time by 20%.',
          'Leveraged Docker for consistent environments, CloudFormation for automated infrastructure, and CI/CD pipelines to enable rapid release cycles, enhancing efficiency and reliability.',
          'Improved application reliability by incorporating resiliency measures to prevent data loss within message queues. Overcame complexities in client usage patterns by harnessing the scalability of Azure Functions and Queues for effective calculations. Streamlined billing processes through daily reports and dashboards, offering clients transparency and insights into usage patterns. Achieved a remarkable 70% improvement in client billing accuracy and a 20% reduction in costs through system enhancements and optimizations.',
          'Prioritized code quality and reliability by consistently writing unit tests, maintaining 90%+ code coverage, and adhering to established best practices.',
          'Enhanced API documentation and collaboration within development teams through the integration of Swagger.',
        ],
      },
      {
        period: 'May 2022 - July 2022',
        role: 'Software Engineer Intern',
        company: 'Consultadd Inc.',
        location: 'Remote',
        responsibilities: [
          'Designed and deployed a fully automated ETL pipeline leveraging AWS Glue, S3, Step Functions, SNS, Cloud-Formation, AWS SAM to fetch census data from a source and filter it for storage in the client’s database.',
          'Engineered Step Functions to orchestrate the flow of AWS Glue jobs, optimizing data extraction, transformation, and loading processes to meet project requirements.',  
              ],
      },
      {
        period: 'Jan 2022 - April 2022',
        role: 'ServiceNow Developer Intern',
        company: 'EY',
        location: 'Remote',
        responsibilities: [
          'Build and tested an ITSM platform using ServiceNow.',  
              ],
      }
    ];
    return (
        <div id='workExp' className='mt-16 workExp'>
        {workexperiences && workexperiences.map((workex, index) => (
                <WorkExpTile 
                    period={workex.period}
                    role={workex.role}
                    company={workex.company}
                    location={workex.location}
                    responsibilities={workex.responsibilities}
                    />
            ))}

        </div>
    )
}

export default WorkExp;