import React from 'react';
import jsLogo from '../assets/icons/jsLogo.svg';
import python from '../assets/icons/python.svg';
import aws from '../assets/icons/aws.svg';
import react from '../assets/icons/react.svg';
import django from '../assets/icons/django.svg';
import flask from '../assets/icons/flask.svg';
import docker from '../assets/icons/docker.svg';
import azure from '../assets/icons/azure.svg';
import mysql from '../assets/icons/mysql.svg';
import pyspark from '../assets/icons/pyspark.svg';


const SkillTile = ({ logo, title }) => (
    <div className="relative">
        <div className="flex flex-col items-center justify-center p-4 bg-white rounded">
            <div className="w-20 h-20 mb-6 mt-4">
                <img src={logo} alt={title} /> 
            </div>

            <span className='text-base font-medium'>{title}</span>
        </div>
    </div>
);

const SkillsGrid = () => {
    const skills = [
        { logo:  jsLogo, title: 'Javascript' },
        { logo: python, title: 'Python' },
        { logo: aws, title: 'AWS' },
        { logo: react, title: 'React.js' },
        { logo: django, title: 'django' },
        { logo: flask, title: 'Flask' },
        { logo: docker, title: 'Docker' },
        { logo: azure, title: 'Azure' },
        { logo: mysql, title: 'MySQL' },
        { logo: '', title: 'PySpark' },
        { logo: pyspark, title: 'PySpark' },

    ];

    return (
        <div id='skills' className="grid grid-cols-3 gap-4 mt-32 ml-2 mr-2 mb-16 skills">
            {skills.map((skill, index) => (
                skill.logo===''?<div></div>:(<div key={index} className={(index - 1) % 3 === 0 ? '-mt-16' : ''}>
                    <SkillTile logo={skill.logo} title={skill.title} />
                </div>)
            ))}
        </div>
    );
};

export default SkillsGrid;