import Profile from "../assets/images/dp.jpeg";
import linkedin from "../assets/icons/linkedin.svg";
import github from "../assets/icons/github.svg";
import leetcode from "../assets/icons/leetcode.png";
import { Link } from "react-scroll";

function About() {
  const sections = ["Work Experience", "Skills", "Projects", "Certifications"];

  return (
    <div className="left">
      <div className="dp w-64 h-64 border-10 border-white rounded-lg overflow-hidden shadow-xl">
        <img
          src={Profile}
          alt=""
          className="object-cover h-full w-full rounded-lg "
        />
      </div>
      <div className="get-in-touch">
        <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
          Get in Touch
        </button>
      </div>
      <div className="mt-8">
        <p className="text-5xl font-bold ">Hello, I'm</p>
        <p className="text-6xl font-bold text-blue-500 mt-3">Prakhar Patidar</p>
        <p className="text-3xl font-bold mt-3">Software Engineer</p>
        <p className="text-xl font-semibold text-gray-500 mt-3">
          Based out Pune, India. Specialized in building web and backend
          systems. if you are a employer looking to hire. you can get in touch
          with me here.
        </p>
      </div>
      <div className="mt-4 text-lg font-semibold">
        {sections.map((section) => (
          <li key={section}>
            <Link
              to={section}
              smooth={true}
              duration={300}
              spy={true}
              offset={-80}
              activeClass="active"
              className="text-gray-800 transition-colors duration-300"
            >
              {section}
            </Link>
          </li>
        ))}
      </div>
      <div className="flex flex-row justify-around mt-8 font-semibold text-gray-800">
        <a href="https://www.linkedin.com/in/prakharpatidar">
          <div className="flex">
            <img className="w-8" src={linkedin}></img>
            <p className="mt-1 ml-2">LinkedIn</p>
          </div>
        </a>
        <div className="flex">
          <img className="w-8" src={github}></img>
          <p className="mt-1 ml-2">GitHub</p>
        </div>
        <div className="flex">
          <img className="w-8" src={leetcode}></img>
          <p className="mt-1 ml-2">Leetcode</p>
        </div>
      </div>
    </div>
  );
}

export default About;
