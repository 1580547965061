import './App.css';
import About from './components/About';
import Content from './components/Content';


function App() {
  return (
    <div className="App">
      <div className='cover'> 
        <About></About>
        <div></div>
        <div className='right'>
          <Content />
        </div>
      </div>
    </div>
  );
}

export default App;
