import React from "react";
import workinprogress from "../assets/icons/work-in-progress.gif";

const Loader = () => {
  return (
    <div className=" p-8 mt-4 mb-8 bg-white rounded">
      <div className="flex flex-col items-center justify-center p-4 rounded">
        <div className="w-20 h-20 mb-6 mt-4">
          <img src={workinprogress} alt={"Work in progress"} />
        </div>

        <span className='text-sm font-semibold text-center'>{"The remaining sections are currently being updated and will be available soon. Please check back later."}</span>

      </div>
    </div>
  );
};

export default Loader;
